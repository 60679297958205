import React from "react"
import Hero from "../components/book-appt/hero"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import ReferralForm from "../components/book-appt/referral_form"
import { graphql } from "gatsby"

const BookAppointmentPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <div className="vetPageForm">
        <ReferralForm data={data.contentfulBookAnAppointmentPage} />
      </div>
    </Layout>
  )
}

export default BookAppointmentPage

export const query = graphql`
  {
    contentfulBookAnAppointmentPage {
      referralLinks {
        id
        title
        content {
          content
        }
        url
      }
      referralSlider {
        id
        testimonial {
          testimonial
        }
        client
      }
    }
  }
`
